import { PropsWithChildren } from "react";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import RAFGridTemplates from "../../../helpers/RAFGridTemplates";
import RAFGrid4 from "../../../RAFComponents/Grid/RAFGrid4";
import { Constants } from "../../../constants/Common/Constants";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import { ChecklistItemTransVersionHistoryColumnListTemplates } from "../../ActiveContacts/Document/DocumentVersionHistory/DocumentVersionHistoryTemplate";

interface IProps {
  onUpdate: () => void;
  checklistItemTransRow: ChecklistItemTransRow;
  checklistItemTransRowUID: string;
  module: string;
  isActive: boolean;
  onClickUploadItem: () => void;
}
function ChecklistItemTransVersionHistory({
  ...props
}: PropsWithChildren<IProps>) {
  return (
    <div className="h-100 p-3 pt-0">
      <RAFFieldStateProvider
        moduleName={RAFEntityName.VersionHistory}
        // viewId={currentView.UID}
      >
        <RAFGridTemplates>
          <RAFGrid4
            gridId={`grid3_${RAFEntityName.VersionHistory}_relatedList`}
            url={`${Constants.baseAPIUrl}ChecklistItemTrans/ListVersions`}
            additionalParams={[
              {
                key: "EntityId",
                value: props.checklistItemTransRowUID,
              },
            ]}
            moduleName={RAFEntityName.VersionHistory}
            isRemote
            showEditColumn={false}
            allowSelection={false}
            allowEditing
            allowFiltering={false}
            emptyStateProps={{
              title: "No results found.",
              body: "Try adjusting your search or changing your filter to find what you're looking for!",
            }}
            gridTemplates={ChecklistItemTransVersionHistoryColumnListTemplates}
            filterBarVisibile={false}
            disableFirstColumnTemplate
            cssClass="hide-tableHeader ListGrid"
            showHeader={false}
            gridLines="None"
          >
            <RAFGridColumn
              field="VersionNumber"
              headerText="VersionNumber"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={"300px"}
            ></RAFGridColumn>
          </RAFGrid4>
        </RAFGridTemplates>
      </RAFFieldStateProvider>
    </div>
  );
}
export default ChecklistItemTransVersionHistory;
