import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFRelatedToRow } from "../../../RAFComponents/models/Common/RAFRelatedToRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";
import { ChecklistTemplateItemRow } from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemRow";
import {
  ChecklistItemTransFilterRow,
  convertChecklistItemTransFilterRowRAFCustomFilter,
} from "./ChecklistItemTransRelatedIndexPage/ChecklistItemTransRelatedIndexPageHelper";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";

export enum RAFChecklistItemTransStatus {
  NotStarted = "Not Started",
  NotUploaded = "Not Uploaded",
  Uploaded = "Uploaded",
  Approved = "Approved",
  Rejected = "Rejected",
  Archived = "Archived",
  Pending = "Pending",
}

export enum RAFChecklistItemTransDocStatus {
  NotUploaded = "Not Uploaded",
  Active = "Active",
  Rejected = "Rejected",
  Pending = "Pending",
  Expired = "Expired",
  ExpiringSoon = "Expiring Soon",
}

export class ChecklistItemTransSaveRequest {
  RelatedToUID?: string;
  RelatedTo?: string;
  RelatedToType?: string;
  ChecklistItems?: LookUpRow[];
}

class ChecklistTemplateItemRequest {
  ChecklistTemplateUID: string;
  RelatedToUID: string;
  RelatedToType: string;
}

export const generateChecklistItemTrans = (
  relatedUID: string,
  relatedToType: string
) => {
  return new Promise<boolean>((resolve) => {
    if (
      IsNotNullOrWhiteSpace(relatedUID) &&
      IsNotNullOrWhiteSpace(relatedToType)
    ) {
      const relatedTo = new RAFRelatedToRow();
      relatedTo.Type = relatedToType;
      relatedTo.UID = relatedUID;

      return repositoryActions
        .postDataAndGetResponse(
          "ChecklistItemTrans/Generate",
          relatedTo,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotEmptyArray(response.data)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    }
  });
};

export const getChecklistItemTransListByRelatedToUID = (
  relatedToUID: string,
  viewUID: string
) => {
  return new Promise<ChecklistItemTransRow[]>((resolve) => {
    if (IsNotNullOrWhiteSpace(relatedToUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(relatedToUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ChecklistItemTransRow>("RelatedToUID");
      relatedFilter.Rules.push(filter1);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;
      listServiceRequest.ViewUID = viewUID;

      return repositoryActions
        .postDataAndGetResponse(
          "ChecklistItemTrans/List",
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getChecklistItemTransListBychecklistItemTransFilter = (
  checklistItemTransFilterRow: ChecklistItemTransFilterRow,
  selectedChecklistItemTransViewUID: string
) => {
  return new Promise<ChecklistItemTransRow[]>((resolve) => {
    if (checklistItemTransFilterRow) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      const checklistItemTransFilter =
        convertChecklistItemTransFilterRowRAFCustomFilter(
          checklistItemTransFilterRow
        );

      if (isNotEmptyArray(checklistItemTransFilter)) {
        checklistItemTransFilter.forEach((filter) => {
          customFilter.Rules.push(filter);
        });
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;
      listServiceRequest.ViewUID = selectedChecklistItemTransViewUID;

      return repositoryActions
        .postDataAndGetResponse(
          "ChecklistItemTrans/List",
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const saveChecklistItemTransAPI = (
  checklistItemTransRow: ChecklistItemTransRow,
  isRevision?: boolean
) => {
  return new Promise<{ entityId: string; objectName: string; }>(
    async (resolve) => {
      if (isNotNullAndUndefined(checklistItemTransRow)) {
        const url = isRevision
          ? "ChecklistItemTrans/Revise"
          : "ChecklistItemTrans/Save";
        repositoryActions
          .postDataAndGetResponse(
            url,
            getSaveRequest(checklistItemTransRow, checklistItemTransRow.UID),
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              resolve({
                entityId: response.data.EntityId,
                objectName: response.data.ObjectName,
              });
            } else {
              resolve({ entityId: null, objectName: null });
            }
          })
          .catch((error) => resolve({ entityId: null, objectName: null }));
      } else {
        resolve({ entityId: null, objectName: null });
      }
    }
  );
};

export const getChecklistTemplateItemGetRequiredItems = (
  checklistTemplateUID: string,
  relatedToUID: string,
  relatedToType: string
) => {
  return new Promise<ChecklistTemplateItemRow[]>((resolve) => {
    if (
      isNotNullAndUndefined(checklistTemplateUID) &&
      isNotNullAndUndefined(relatedToUID) &&
      isNotNullAndUndefined(relatedToType)
    ) {
      const checklistTemplateItemRequest = new ChecklistTemplateItemRequest();
      checklistTemplateItemRequest.ChecklistTemplateUID = checklistTemplateUID;
      checklistTemplateItemRequest.RelatedToUID = relatedToUID;
      checklistTemplateItemRequest.RelatedToType = relatedToType;

      repositoryActions
        .postDataAndGetResponse(
          "ChecklistTemplateItem/GetRequiredItems",
          checklistTemplateItemRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const saveListchecklistTemplateItems = (
  checklistItemTransSaveRequest: ChecklistItemTransSaveRequest
) => {
  return new Promise<boolean>((resolve) => {
    if (
      isNotNullAndUndefined(checklistItemTransSaveRequest) &&
      isNotEmptyArray(checklistItemTransSaveRequest.ChecklistItems)
    ) {
      repositoryActions
        .postDataAndGetResponse(
          "ChecklistItemTrans/SaveList",
          checklistItemTransSaveRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotEmptyArray(response.data)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => resolve(false));
    } else {
      resolve(false);
    }
  });
};

export const getChecklistTemplateItemMasterListByChecklistTemplateUID = (checklistTemplateUID: string) => {
  return new Promise<ChecklistTemplateItemRow[]>((resolve) => {
    if (
      isNotNullAndUndefined(checklistTemplateUID)
    ) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(checklistTemplateUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ChecklistItemTransRow>("ChecklistTemplateUID");
      relatedFilter.Rules.push(filter1);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      repositoryActions
        .postDataAndGetResponse(
          "ChecklistTemplateItem/MasterList",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getAllChecklistTemplateMasterList = () => {
  return new Promise<ChecklistTemplateItemRow[]>((resolve) => {
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    repositoryActions
      .postDataAndGetResponse(
        "ChecklistTemplate/MasterList",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotEmptyArray(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const saveChecklistTemplateItemCloneItems = (destinationTemplateUID: string, sourceItemGuids: string[]) => {
  return new Promise<boolean>(async (resolve) => {
    if (isNotEmptyArray(sourceItemGuids)) {
      const saveRequest = {
        DestinationTemplateUID: destinationTemplateUID,
        SourceItemGuids: sourceItemGuids
      };
      repositoryActions
        .postDataAndGetResponse(
          "ChecklistTemplateItem/CloneItems",
          saveRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => resolve(false));
    } else {
      resolve(false);
    }
  });
};
