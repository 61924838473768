import React, { PropsWithChildren, Reducer, useContext, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import { RAFFormContext, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import { hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { isNotEmptyArray } from "../../../RAFComponents/helpers/utils";
import { getChecklistTemplateItemMasterListByChecklistTemplateUID } from "../ChecklistItemTrans/ChecklistItemTransHelper";
import ChecklistTemplateItemsFormContent from "../ChecklistItemTrans/ChecklistTemplateItemsFormContent";
import { ChecklistTemplateItemRow } from "./ChecklistTemplateItem/ChecklistTemplateItemRow";

interface IProps {
    checklistTemplateMasterList: ChecklistTemplateItemRow[];
}

interface IState {
    checkListTemplateItems: ChecklistTemplateItemRow[];
    selectedChecklistTemplateItemsUID: string[];
}

function CreateTemplateFromLibraryContent({ ...props }: PropsWithChildren<IProps>) {
    const outerDivId = `create_update_checklistTemplate_dialog`;
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            checkListTemplateItems: [],
            selectedChecklistTemplateItemsUID: null
        }
    );

    const onChangeChecklistTemplate = async (label: string, value: string) => {
        let progressDiv = showProgress(`#${outerDivId}`);
        const checkListTemplateItems: ChecklistTemplateItemRow[] = await getChecklistTemplateItemMasterListByChecklistTemplateUID(value);
        hideProgress(progressDiv);
        setFormValue(rafFormContextValue, "SelectedChecklistTemplateItemsUID", null);
        setState({ checkListTemplateItems, selectedChecklistTemplateItemsUID: null });
    };

    const onChangeCheckBoxValue = (
        isChecked: boolean,
        selectedItemUID: string
    ) => {
        const newSelectedChecklistTemplateItemsUID = isNotEmptyArray(state.selectedChecklistTemplateItemsUID)
            ? [...state.selectedChecklistTemplateItemsUID]
            : [];
        if (isChecked) {
            if (!newSelectedChecklistTemplateItemsUID.includes(selectedItemUID)) {
                newSelectedChecklistTemplateItemsUID.push(selectedItemUID);
            }
        } else {
            if (newSelectedChecklistTemplateItemsUID.includes(selectedItemUID)) {
                newSelectedChecklistTemplateItemsUID.splice(
                    newSelectedChecklistTemplateItemsUID.indexOf(selectedItemUID),
                    1
                );
            }
        }
        setFormValue(rafFormContextValue, "SelectedChecklistTemplateItemsUID", newSelectedChecklistTemplateItemsUID);
        setState({ selectedChecklistTemplateItemsUID: newSelectedChecklistTemplateItemsUID });
    };


    const updateSelectedChecklistTemplateItemsUID = (selectedChecklistTemplateItemsUID: string[]) => {
        setFormValue(rafFormContextValue, "SelectedChecklistTemplateItemsUID", selectedChecklistTemplateItemsUID);
        setState({ selectedChecklistTemplateItemsUID });
    };


    const getChecklistTemplateLookUp = () => {

        return (
            <RAFDropdownCC
                field="SelectedChecklistTemplate"
                label="Requirements Template"
                placeholder="Select Requirements Template"
                showClearButton
                allowFiltering
                onChanged={onChangeChecklistTemplate}
                required
                formGroupClassName="mb-0"
            >
                {isNotEmptyArray(props.checklistTemplateMasterList) && props.checklistTemplateMasterList.map((item) => {
                    return (
                        <RAFChoiceOption key={item.UID} value={item.UID} label={item.Title} />
                    );
                })}
            </RAFDropdownCC>
        );
    };

    const renderChecklistTemplateItemsContent = () => {
        return (
            <ChecklistTemplateItemsFormContent
                checkListTemplateItems={state.checkListTemplateItems}
                selectedChecklistTemplateItemsUID={state.selectedChecklistTemplateItemsUID}
                onChangeCheckBoxValue={onChangeCheckBoxValue}
                updateSelectedChecklistTemplateItemsUID={updateSelectedChecklistTemplateItemsUID}
            />
        );
    };

    return (
        <div className="row gx-2 gy-3">
            {getChecklistTemplateLookUp()}
            {renderChecklistTemplateItemsContent()}
        </div>
    );
}

export default React.memo(CreateTemplateFromLibraryContent);