import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, { PropsWithChildren, Reducer, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import {
  ChecklistTemplateItemRow,
  ChecklistTemplateItemType,
} from "./ChecklistTemplateItemRow";
import ManageChecklistTemplateItem from "./ManageChecklistTemplateItem";

interface IProps {
  checklistTemplateEntity: string;
  checklistTemplateEntityDisplayName: string;
  allCheckListTemplateItems: ChecklistTemplateItemRow[];
  checklistTemplateItemRow: ChecklistTemplateItemRow;
  childCheckListTemplateItems: ChecklistTemplateItemRow[];
  onSave: () => void;
  allowAddEdit: boolean;
  isCollapsed: boolean;
  showCheckBox: boolean;
  isChecked: boolean;
  selectedChecklistTemplateItemsUID: string[];
  onChangeCheckBoxValue: (isChecked: boolean, uid: string) => void;
  isAllChildItemsChecked: boolean;
  onSelectAllOrUnSelectAllClicked: (childCheckListTemplateItems: ChecklistTemplateItemRow[], action: 'select' | 'unSelect') => void;
}

interface IState {
  isCollapsed: boolean;
  showManageChecklistTemplateItemContent: boolean;
  dlgOpenMode: "update" | "createChild";
}

function ChecklistTemplateItemCardContent({
  checklistTemplateItemRow,
  ...props
}: PropsWithChildren<IProps>) {
  const checklistTemplateItemModuleName =
    CareEsioEntity.ChecklistTemplateItem.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isCollapsed:
        checklistTemplateItemRow.Type === ChecklistTemplateItemType.Item
          ? true
          : props.isCollapsed
            ? props.isCollapsed
            : false,
      showManageChecklistTemplateItemContent: false,
      dlgOpenMode: null,
    }
  );

  //manage ChecklistTemplateItem item content starts
  const showManageChecklistTemplateItemDialog = (
    dlgOpenMode: "update" | "createChild"
  ) => {
    setState({
      showManageChecklistTemplateItemContent: true,
      dlgOpenMode,
    });
  };

  const manageChecklistTemplateItemContent = () => {
    if (state.showManageChecklistTemplateItemContent) {
      const { dlgOpenMode } = state;
      return (
        <ManageChecklistTemplateItem
          objectUID={
            dlgOpenMode === "update" ? checklistTemplateItemRow.UID : null
          }
          onClose={closeManageChecklistTemplateItemDialog.bind(this)}
          onSave={refreshOnSave.bind(this)}
          checklistTemplate={checklistTemplateItemRow.ChecklistTemplate}
          checklistTemplateEntity={props.checklistTemplateEntity}
          checklistTemplateUID={checklistTemplateItemRow.ChecklistTemplateUID}
          checklistTemplateParent={checklistTemplateItemRow.Title}
          checklistTemplateParentUID={checklistTemplateItemRow.UID}
          isChildItemsExist={isNotEmptyArray(props.childCheckListTemplateItems)}
          checklistTemplateEntityDisplayName={
            props.checklistTemplateEntityDisplayName
          }
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const refreshOnSave = () => {
    closeManageChecklistTemplateItemDialog();
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeManageChecklistTemplateItemDialog = () => {
    setState({
      showManageChecklistTemplateItemContent: false,
      dlgOpenMode: null,
    });
  };

  //manage ChecklistTemplateItem item content ends

  const ChecklistTemplateItemIsRequiredBadgeContent = () => {
    if (checklistTemplateItemRow.IsRequired)
      return <div className="raf_badge raf_xsm raf_badge_danger">Required</div>;
  };

  const ChecklistTemplateItemIsComplianceBadgeContent = () => {
    if (checklistTemplateItemRow.IsCompliance)
      return (
        <div className="raf_badge raf_xsm raf_badge_primary">Compliance</div>
      );
  };
  const ChecklistTemplateItemRequiresApprovalBadgeContent = () => {
    if (checklistTemplateItemRow.RequiresApproval)
      return (
        <div className="raf_badge raf_xsm raf_badge_warning">Approval</div>
      );
  };

  const ChecklistTemplateItemAllowUserUploadContent = () => {
    let userUploadDisplayText = "Admin Upload";
    let userUploadDisplayStatus = "raf_badge_success";
    if (checklistTemplateItemRow.AllowUserUpload) {
      userUploadDisplayText = "User Upload";
      userUploadDisplayStatus = "raf_badge_quaternary";
    }
    return (
      <div className={`raf_badge raf_xsm ${userUploadDisplayStatus}`}>
        {userUploadDisplayText}
      </div>
    );
  };

  const checkListTemplateValidityBadgeContent = () => {
    return (
      <div className="raf_badge raf_xsm">
        {`Validity: ${checklistTemplateItemRow.ProcessDeadlineInterval}-${checklistTemplateItemRow.ProcessDeadlineUnits}`}
      </div>
    );
  };

  const checkListTemplateHasExpiryDateBadgeContent = () => {
    return <div className="raf_badge raf_xsm raf_badge_danger">Has Expiry</div>;
  };

  const ChecklistTemplateItemBadgeContent = () => {
    return (
      <div className="row gx-2">
        {checklistTemplateItemRow.HasValidity &&
          isNotNullAndUndefined(
            checklistTemplateItemRow.ProcessDeadlineUnits
          ) &&
          isNotNullAndUndefined(
            checklistTemplateItemRow.ProcessDeadlineInterval
          ) && (
            <div className="col-auto">
              {checkListTemplateValidityBadgeContent()}
            </div>
          )}
        {checklistTemplateItemRow.HasExpiryDate && (
          <div className="col-auto">
            {checkListTemplateHasExpiryDateBadgeContent()}
          </div>
        )}
        {checklistTemplateItemRow.IsCompliance && (
          <div className="col-auto">
            {ChecklistTemplateItemIsComplianceBadgeContent()}
          </div>
        )}
        {checklistTemplateItemRow.IsRequired && (
          <div className="col-auto">
            {ChecklistTemplateItemIsRequiredBadgeContent()}
          </div>
        )}
        {checklistTemplateItemRow.RequiresApproval && (
          <div className="col-auto">
            {ChecklistTemplateItemRequiresApprovalBadgeContent()}
          </div>
        )}
        <div className="col-auto">
          {ChecklistTemplateItemAllowUserUploadContent()}
        </div>
      </div>
    );
  };

  function checklistTemplateItemCustomTitle() {
    const showCheckBox =
      checklistTemplateItemRow.Type === ChecklistTemplateItemType.Item
        ? props.showCheckBox
        : false;
    const isChecked = props.isChecked ?? false;
    return (
      <div
        id={checklistTemplateItemRow.UID}
        className="actionSection pointer"
        onClick={() => {
          if (showCheckBox) {
            if (props.onChangeCheckBoxValue) {
              props.onChangeCheckBoxValue(
                !isChecked,
                checklistTemplateItemRow.UID
              );
            }
          }
        }}
      >
        <div className={`row gx-2 align-items-center`}>
          {showCheckBox && (
            <div
              key={isChecked ? isChecked.toString() : ""}
              className={`col-auto`}
            >
              <div style={{ marginTop: "0.3125rem" }}>
                <CheckBoxComponent
                  name={checklistTemplateItemRow.UID}
                  value={checklistTemplateItemRow.UID}
                  cssClass="e-checkbox-light"
                  // change={(e) => onChangeCheckBoxValue(e.checked, checklistTemplateItemRow.UID)}
                  checked={isChecked ?? false}
                />
              </div>
            </div>
          )}
          <div className="col">
            <div className="d-flex flex-nowrap">
              <div className="">
                <div className="row g-0">
                  <div className="col-12">
                    <span
                      className={`${showCheckBox
                        ? `${checklistTemplateItemRow.IsRequired
                          ? "content_state_danger_base "
                          : ""
                        }body_2`
                        : " subtitle_1 semi_bold"
                        }`}
                    >
                      {checklistTemplateItemRow.Title}
                    </span>
                  </div>
                  {IsNotNullOrWhiteSpace(
                    checklistTemplateItemRow.Description
                  ) &&
                    !showCheckBox && (
                      <div className="col-12">
                        <span
                          className="description-text body_2_light"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              checklistTemplateItemRow.Description
                            ),
                          }}
                        ></span>
                      </div>
                    )}
                  {!showCheckBox &&
                    isNotNullAndUndefined(
                      checklistTemplateItemRow.ParentUID
                    ) && (
                      <div className="col-12">
                        {ChecklistTemplateItemBadgeContent()}
                      </div>
                    )}
                </div>
              </div>
              {showCheckBox && checklistTemplateItemRow.IsRequired && (
                <div className="ms-2">
                  <span className="content_state_danger_base fa-1x">*</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function checklistTemplateItemCustomButton(type: string) {
    if (props.allowAddEdit === true) {
      return (
        <div className="row align-items-center gx-2">
          <div className="col-auto d-none d-md-block">
            <RAFButtonComponent
              className="btn_brand_primary"
              action={RAFButtonConstant.Edit}
              // iconCss={`${RAFButtonConstant.Edit.IconCss} content_brand_secondary_base`}
              onClick={() => showManageChecklistTemplateItemDialog("update")}
            />
          </div>
          <div className="col-auto d-md-none">
            <RAFButtonComponent
              className="btn_brand_primary"
              action={RAFButtonConstant.Edit}
              // iconCss={`${RAFButtonConstant.Edit.IconCss} content_brand_secondary_base`}
              onClick={() => showManageChecklistTemplateItemDialog("update")}
              iconBtn
            />
          </div>
        </div>
      );
    } else if (props.onSelectAllOrUnSelectAllClicked && type === ChecklistTemplateItemType.Section && isNotEmptyArray(props.childCheckListTemplateItems)) {
      return (
        <div className="col-auto">
          <RAFButtonComponent
            action={
              props.isAllChildItemsChecked ===
                true
                ? RAFButtonConstant.UnSelect
                : RAFButtonConstant.SelectAll
            }
            onClick={() => props.onSelectAllOrUnSelectAllClicked(props.childCheckListTemplateItems, props.isAllChildItemsChecked ? 'unSelect' : 'select')}
            className="e-outline"
            iconBtn={BrowserIsDevice}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  const onExpandedSection = (isCollapsed) => {
    setState({ isCollapsed: !isCollapsed });
  };

  if (isNotNullAndUndefined(checklistTemplateItemRow)) {
    return (
      <>
        <RAFCollapseWithPlusIcon
          toggleArrowIcon="Arrow"
          customTitle={checklistTemplateItemCustomTitle()}
          customButton={checklistTemplateItemCustomButton(checklistTemplateItemRow.Type)}
          //title={checklistTemplateItemRow.Title}
          allowFullRowClick
          isCollapsed={state.isCollapsed}
          onExpandedSection={onExpandedSection}
          collapsible={
            checklistTemplateItemRow.Type === ChecklistTemplateItemType.Section
              ? true
              : false
          }
          layout={RAFLayout.OneColumnLayout}
          collapsePanelContentClassname="surface_neutral_base"
          collapsePanelHeaderClass="py-2 collapsePanel__header__fixedMinHeight"
        >
          {checklistTemplateItemRow.Type ===
            ChecklistTemplateItemType.Section && (
              <>
                {isNotEmptyArray(props.childCheckListTemplateItems) ? (
                  <div className="row gy-2 gx-0">
                    {props.childCheckListTemplateItems.map((item) => {
                      const childCheckListTemplateItems =
                        props.allCheckListTemplateItems.filter(
                          (x) => x.ParentUID === item.UID
                        );
                      const isChecked = isNotEmptyArray(
                        props.selectedChecklistTemplateItemsUID
                      )
                        ? props.selectedChecklistTemplateItemsUID.includes(
                          item.UID
                        )
                        : false;

                      const isAllChildItemsChecked = isNotEmptyArray(childCheckListTemplateItems) && isNotEmptyArray(props.selectedChecklistTemplateItemsUID)
                        ? childCheckListTemplateItems.every(x => props.selectedChecklistTemplateItemsUID.includes(x.UID)) : false;

                      return (
                        <div className="col-12" key={item.UID}>
                          <CustomCardWidget removeContentPadding>
                            <ChecklistTemplateItemCardContent
                              key={item.UID}
                              checklistTemplateItemRow={item}
                              allCheckListTemplateItems={
                                props.allCheckListTemplateItems
                              }
                              checklistTemplateEntity={
                                props.checklistTemplateEntity
                              }
                              checklistTemplateEntityDisplayName={
                                props.checklistTemplateEntityDisplayName
                              }
                              childCheckListTemplateItems={
                                childCheckListTemplateItems
                              }
                              allowAddEdit={props.allowAddEdit}
                              isCollapsed={props.isCollapsed}
                              isChecked={isChecked}
                              showCheckBox={props.showCheckBox}
                              isAllChildItemsChecked={isAllChildItemsChecked}
                              onSelectAllOrUnSelectAllClicked={props.onSelectAllOrUnSelectAllClicked}
                              onChangeCheckBoxValue={props.onChangeCheckBoxValue}
                              selectedChecklistTemplateItemsUID={
                                props.selectedChecklistTemplateItemsUID
                              }
                              onSave={props.onSave}
                            />
                          </CustomCardWidget>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="col-12">
                    <RAFEmptyState
                      title={`No items to display`}
                      body={`Looks like you haven't added any items yet`}
                      displayStyle="TextOnly"
                    ></RAFEmptyState>
                  </div>
                )}
              </>
            )}
          {checklistTemplateItemRow.Type ===
            ChecklistTemplateItemType.Section &&
            props.allowAddEdit && (
              <div className="d-flex align-items-center justify-content-center">
                <RAFButtonComponent
                  action={RAFButtonConstant.Add}
                  onClick={() =>
                    showManageChecklistTemplateItemDialog("createChild")
                  }
                  isPrimary
                  btnContent={`Add Item`}
                  // className="btn_brand_secondary"
                  uiMode="FooterBtn"
                ></RAFButtonComponent>
              </div>
            )}
        </RAFCollapseWithPlusIcon>
        {state.showManageChecklistTemplateItemContent && (
          <DialogComponent
            header={
              state.dlgOpenMode === "update"
                ? `Update ${CareEsioEntity.ChecklistTemplateItem.DisplayName}`
                : `Add ${CareEsioEntity.ChecklistTemplateItem.DisplayName}`
            }
            showCloseIcon
            visible={state.showManageChecklistTemplateItemContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_checklistTemplateItem_${checklistTemplateItemModuleName}`}
            content={manageChecklistTemplateItemContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeManageChecklistTemplateItemDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          />
        )}
      </>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(ChecklistTemplateItemCardContent);
