import * as R from "ramda";
import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
    IsSuperAdmin,
    hideProgress,
    showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import {
    IDialogProps,
    isNotEmptyArray,
    isNotNullAndUndefined,
    propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getAllEntities } from "../../../RAFMaster/helpers/RMutils";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
    BrowserIsDevice,
    RAFButtonConstant,
    RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { getAllChecklistTemplateMasterList, saveChecklistTemplateItemCloneItems } from "../ChecklistItemTrans/ChecklistItemTransHelper";
import { saveChecklistTemplateAPI } from "./ChecklistTemplateHelper";
import { ChecklistTemplateItemRow } from "./ChecklistTemplateItem/ChecklistTemplateItemRow";
import { ChecklistTemplateRow } from "./ChecklistTemplateRow";
import CreateTemplateFromLibraryContent from "./CreateTemplateFromLibraryContent";

interface IProps {
    initialValues?: ChecklistTemplateRow;
    onDelete?: () => void;
}

interface IState {
    isLoading: boolean;
    noContent: boolean;
    checklistTemplateRow: ChecklistTemplateRow;
    allClientAndEmployeeEntities: EntityRow[];
    selectedTabId: 'AddNewTemplate' | 'AddFromLibrary';
    checklistTemplateMasterList: ChecklistTemplateItemRow[];
}

function CreateChecklistTemplate({
    ...props
}: PropsWithChildren<IProps & IDialogProps>) {
    let rafForm: FormRenderProps | null;

    const moduleName = CareEsioEntity.ChecklistTemplate.EntityName;

    const outerDivId = `create_update_checklistTemplate_dialog`;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            noContent: true,
            allClientAndEmployeeEntities: null,
            checklistTemplateRow: null,
            selectedTabId: 'AddNewTemplate',
            checklistTemplateMasterList: []
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        if (props.isActive) {
            setState({ isLoading: true });
            const [checklistTemplateRow, allEntities, checklistTemplateMasterList] = await Promise.all([
                getIntitailChecklistTemplateRow(),
                getAllEntities(),
                IsSuperAdmin() ? getAllChecklistTemplateMasterList() : null
            ]);

            const allClientAndEmployeeEntities = allEntities.filter(
                (entity) =>
                    entity.EntityName === CareEsioEntity.CareRecipient.EntityName ||
                    entity.EntityName === RAFEntityName.Employee
            );
            if (isNotNullAndUndefined(checklistTemplateRow)) {
                setState({
                    isLoading: false,
                    noContent: false,
                    checklistTemplateRow,
                    allClientAndEmployeeEntities,
                    checklistTemplateMasterList,
                });
            } else {
                setState({ isLoading: false, noContent: true });
            }
        }
    };

    const getIntitailChecklistTemplateRow = () => {
        return new Promise<ChecklistTemplateRow>(async (resolve) => {
            if (props.initialValues) {
                resolve(props.initialValues);
            } else {
                let initialObject = new ChecklistTemplateRow();
                initialObject.Status = "Active";
                resolve(initialObject);
            }
        });
    };

    const onSubmitChecklistTemplate = async (formValue: ChecklistTemplateRow) => {
        let progressDiv = showProgress(`#${outerDivId}`);
        const value = R.clone(formValue);
        if (state.selectedTabId === 'AddNewTemplate') {
            delete value.SelectEntityUID;
            const response = await saveChecklistTemplateAPI(value);
            hideProgress(progressDiv);
            if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.entityId)
            ) {
                if (isNotNullAndUndefined(props.onSave)) {
                    props.onSave(response.entityId);
                }
            } else {
                showWarningToast(
                    "Apologies, we're unable to save the record at the moment. Please try again later.!"
                );
            }
        } else if (state.selectedTabId === 'AddFromLibrary') {
            const sourceItemGuids = value.SelectedChecklistTemplateItemsUID;
            if (isNotEmptyArray(sourceItemGuids)) {
                const response = await saveChecklistTemplateItemCloneItems(null, sourceItemGuids);
                hideProgress(progressDiv);
                if (
                    response
                ) {
                    if (isNotNullAndUndefined(props.onSave)) {
                        props.onSave();
                    }
                } else {
                    showWarningToast(
                        "Apologies, we're unable to save the record at the moment. Please try again later.!"
                    );
                }
            } else {
                hideProgress(progressDiv);
                showWarningToast("Please select at least one item to clone.");
            }
        }
    };

    const onChangeEntity = (label, value) => {
        const { allClientAndEmployeeEntities } = state;
        if (isNotNullAndUndefined(value)) {
            const selectedItem = allClientAndEmployeeEntities.find(
                (item) => item.UID === value
            );

            setFormValue(
                rafForm,
                propertyOf<ChecklistTemplateRow>("Entity"),
                selectedItem.DisplayName
            );
            setFormValue(
                rafForm,
                propertyOf<ChecklistTemplateRow>("EntityUID"),
                value
            );
            setFormValue(
                rafForm,
                propertyOf<ChecklistTemplateRow>("EntityType"),
                selectedItem.EntityName
            );
        } else {
            setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("Entity"), null);
            setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("EntityType"), null);
            setFormValue(rafForm, propertyOf<ChecklistTemplateRow>("EntityUID"), null);
        }
    };

    const getAddFromLibraryContent = () => {
        return (
            <CreateTemplateFromLibraryContent
                checklistTemplateMasterList={state.checklistTemplateMasterList}
            />
        );
    };

    const getAddNewTemplateContent = () => {
        return (
            <div className="row gx-2 gy-4">
                <div className="col-md-12">
                    <ACTextBox
                        field={propertyOf<ChecklistTemplateRow>("Title")}
                        formGroupClassName="mb-0"
                        required
                    />
                </div>
                <div className="col-md-12">
                    <RAFHtmlEditor
                        field={propertyOf<ChecklistTemplateRow>(
                            "Description"
                        )}
                        label="Description"
                        showLabel={true}
                        placeholder="Description"
                        rows={5}
                        fullHeight={false}
                        rowClassName="row g-0 gy-2"
                        useMentions={false}
                        mentionsField={null}
                        formGroupClassName="mb-0"
                    />
                </div>
                <div className="col-md-12">
                    <ACDropdown
                        field={propertyOf<ChecklistTemplateRow>("Status")}
                        label="Status"
                        moduleName={moduleName}
                        showLabel={true}
                        allowAdd={false}
                        required
                        formGroupClassName="mb-0"
                    />
                </div>
                <div className="col-md-12">
                    <RAFDropdownCC
                        field={propertyOf<ChecklistTemplateRow>(
                            "SelectEntityUID"
                        )}
                        label="Module"
                        showClearButton={false}
                        placeholder="Module"
                        required
                        showLabel
                        allowFiltering
                        onChanged={onChangeEntity}
                        formGroupClassName="mb-0"
                    >
                        {isNotEmptyArray(
                            state.allClientAndEmployeeEntities
                        ) &&
                            state.allClientAndEmployeeEntities.map((item) => {
                                return (
                                    <RAFChoiceOption
                                        key={item.UID}
                                        label={item.DisplayName}
                                        value={item.UID}
                                    />
                                );
                            })}
                    </RAFDropdownCC>
                </div>
            </div>
        );
    };

    const renderTabContent = () => {
        if (state.selectedTabId === "AddNewTemplate") {
            return (
                getAddNewTemplateContent()
            );
        } else if (state.selectedTabId === "AddFromLibrary") {
            return (
                getAddFromLibraryContent()
            );
        }
    };

    const updateSelectedTab = (selectedTabId: 'AddNewTemplate' | 'AddFromLibrary') => {
        if (isNotNullAndUndefined(rafForm)) {
            rafForm.form.reset();
        }
        setState({ selectedTabId });
    };

    const getBodyContet = () => {
        if (isNotEmptyArray(state.checklistTemplateMasterList)) {
            const tabItems: {
                id: 'AddNewTemplate' | 'AddFromLibrary';
                text: string;
            }[] = [
                    {
                        id: 'AddNewTemplate',
                        text: "Add New Template",
                    },
                    {
                        id: 'AddFromLibrary',
                        text: "Add from Library",
                    },
                ];

            return (
                <>
                    <div className="border-bottom ps-1">
                        <div
                            id="raf_tab_header_manage_task_container"
                            className="raf_tab_header raf_lg secondary_tab hideScrollBar full_width_tab"
                        >
                            {!BrowserIsDevice && (
                                <RAFButtonComponent
                                    iconBtn
                                    iconCss="fas fa-chevron-left"
                                    className="bg-white border-0 custom-button-lg"
                                    id="raf_tab_header_manage_task_left_scroll"
                                    enableToolTip={false}
                                ></RAFButtonComponent>
                            )}
                            <div
                                id="raf_tab_header_manage_task"
                                className="raf_tab_header raf_lg hideScrollBar"
                            >
                                {tabItems.map((x) => {
                                    return (
                                        <div
                                            className={`raf_tab_header_item${state.selectedTabId === x.id
                                                ? " active"
                                                : ""
                                                }`}
                                            key={x.id}
                                            onClick={() => updateSelectedTab(x.id)}
                                        >
                                            <span>{x.text}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            {!BrowserIsDevice && (
                                <RAFButtonComponent
                                    iconBtn
                                    iconCss="fas fa-chevron-right"
                                    className="bg-white border-0 custom-button-lg"
                                    id="raf_tab_header_manage_task_right_scroll"
                                    enableToolTip={false}
                                ></RAFButtonComponent>
                            )}
                        </div>
                    </div>
                    <div className="e-dlg-body-content pt-2 pt-md-3">
                        {renderTabContent()}
                    </div>
                </>
            );
        } else {
            return (
                <div className="e-dlg-body-content">
                    {getAddNewTemplateContent()}
                </div>
            );
        }
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            if (state.noContent === false) {
                return (
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFForm
                                type={ChecklistTemplateRow}
                                initialValues={state.checklistTemplateRow}
                                formRef={(g) => (rafForm = g)}
                                layout={RAFLayout.TwoColumnLayout}
                                onSubmit={onSubmitChecklistTemplate}
                                className="h-100"
                            >
                                <div className="e-dlg-content-outer" id={outerDivId}>
                                    {getBodyContet()}
                                    <div className="e-dlg-footerContent ">
                                        <div className="w-100">
                                            <div className="row gx-2">
                                                <div className="col-auto ms-auto">
                                                    <RAFButtonComponent
                                                        type="button"
                                                        isPrimary
                                                        action={RAFButtonConstant.Save}
                                                        onClick={() => rafForm && rafForm.form.submit()}
                                                        idString="CreateContent"
                                                        disabled={rafForm && rafForm.submitting}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <RAFButtonComponent
                                                        type="button"
                                                        action={RAFButtonConstant.Cancel}
                                                        onClick={props.onClose}
                                                        idString="CreateContent"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RAFForm>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                );
            } else {
                return (
                    <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                    </div>
                );
            }
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Loading..." />
                </div>
            );
        }
    } else {
        return <div></div>;
    }
}

export default React.memo(CreateChecklistTemplate);
