import React, { PropsWithChildren } from "react";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import { IsNullOrWhiteSpace, isNotEmptyArray } from "../../../RAFComponents/helpers/utils";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import ChecklistTemplateItemCardContent from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemCardContent";
import { ChecklistTemplateItemRow } from "../ChecklistTemplate/ChecklistTemplateItem/ChecklistTemplateItemRow";

interface IProps {
    selectedChecklistTemplateItemsUID: string[];
    checkListTemplateItems: ChecklistTemplateItemRow[];
    onChangeCheckBoxValue: (isChecked: boolean, selectedItemUID: string) => void;
    updateSelectedChecklistTemplateItemsUID: (selectedChecklistTemplateItemsUID: string[]) => void;
}

function ChecklistTemplateItemsFormContent({ selectedChecklistTemplateItemsUID, checkListTemplateItems, onChangeCheckBoxValue, ...props }: PropsWithChildren<IProps>) {

    const onSelectAllOrUnSelectAllClicked = (childCheckListTemplateItems: ChecklistTemplateItemRow[], action: 'select' | 'unSelect') => {
        if (isNotEmptyArray(childCheckListTemplateItems)) {
            if (action === 'select') {
                const newSelectedChecklistTemplateItemsUID = isNotEmptyArray(selectedChecklistTemplateItemsUID) ? [...selectedChecklistTemplateItemsUID] : [];
                childCheckListTemplateItems.forEach((item) => {
                    if (!newSelectedChecklistTemplateItemsUID.includes(item.UID)) {
                        newSelectedChecklistTemplateItemsUID.push(item.UID);
                    }
                });
                props.updateSelectedChecklistTemplateItemsUID(newSelectedChecklistTemplateItemsUID);
            } else if (action === 'unSelect') {
                const newSelectedChecklistTemplateItemsUID = selectedChecklistTemplateItemsUID.filter(x => !childCheckListTemplateItems.map(y => y.UID).includes(x));
                props.updateSelectedChecklistTemplateItemsUID(newSelectedChecklistTemplateItemsUID);
            }
        }
    };

    if (isNotEmptyArray(checkListTemplateItems)) {
        const parentCheckListTemplateItems = checkListTemplateItems.filter(x => IsNullOrWhiteSpace(x.ParentUID));
        return (
            <div
                className="col-12"
                id={`care_${CareEsioEntity.ChecklistTemplateItem.EntityName}_div`}
            >
                <div className="row gy-3 gx-0">

                    <div className="col-12">
                        <div className="checklist_template_item_card_header">
                            <span>
                                {CareEsioEntity.ChecklistItemTrans.DisplayName}
                            </span>
                        </div>
                    </div>
                    {parentCheckListTemplateItems.map((item) => {
                        const childCheckListTemplateItems = checkListTemplateItems.filter(x => x.ParentUID === item.UID);
                        const isChecked = isNotEmptyArray(selectedChecklistTemplateItemsUID)
                            ? selectedChecklistTemplateItemsUID.includes(item.UID)
                            : false;
                        const isAllChildItemsChecked = (isNotEmptyArray(childCheckListTemplateItems) && isNotEmptyArray(selectedChecklistTemplateItemsUID))
                            ? childCheckListTemplateItems.every(x => selectedChecklistTemplateItemsUID.includes(x.UID)) : false;
                        return (
                            <div className="col-12" key={item.UID}>
                                <CustomCardWidget cardClassName="overflow-hidden"
                                    removeContentPadding>
                                    <ChecklistTemplateItemCardContent
                                        key={item.UID}
                                        checklistTemplateItemRow={item}
                                        checklistTemplateEntity={null}
                                        checklistTemplateEntityDisplayName={null}
                                        allCheckListTemplateItems={checkListTemplateItems}
                                        childCheckListTemplateItems={childCheckListTemplateItems}
                                        allowAddEdit={false}
                                        isCollapsed={false}
                                        onSave={null}
                                        showCheckBox
                                        isChecked={isChecked}
                                        isAllChildItemsChecked={isAllChildItemsChecked}
                                        selectedChecklistTemplateItemsUID={selectedChecklistTemplateItemsUID}
                                        onChangeCheckBoxValue={onChangeCheckBoxValue}
                                        onSelectAllOrUnSelectAllClicked={onSelectAllOrUnSelectAllClicked}
                                    />
                                </CustomCardWidget>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    } else {
        return (
            <div className="col-12">
                <RAFEmptyState
                    title={`Checklist Template Item`}
                    body={`No Checklist Template Items available at this time.`}
                    displayStyle="TextOnly"
                />
            </div>
        );
    }
}

export default React.memo(ChecklistTemplateItemsFormContent);